import React from "react";
import { Helmet } from "react-helmet";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { useState } from 'react';
import { SIGNUP_TYPES, sendEmail } from '../components/SendEmail'
import Button from 'react-bootstrap/Button';

export function ComingSoon() {
    const WAITLIST_SIGNUP_COMPLETE_MESSAGE = "waitlist-signup-complete-message";

    const [email, setEmail] = useState('')

    const handleEmailChange = event => {
        setEmail(event.target.value)
    };

    const handleSubmit = event => {
        event.preventDefault()
        sendEmail(email, SIGNUP_TYPES.VIRE_WAITLIST, 'sign-up-button', WAITLIST_SIGNUP_COMPLETE_MESSAGE)
    };
    
    return (
        <div >
            <Helmet>
                <title>Vire: Coming Soon</title>
            </Helmet>
            <div className="coming-soon-page">
            <div>
                <h3 className="job-h3"><b className="job-h3-purple">Coming Soon!</b></h3>
                <p>Sign up to get early access.</p>
                <p className="signup-complete-message" id={WAITLIST_SIGNUP_COMPLETE_MESSAGE}>Thanks, we'll be in touch soon.</p>
                <form onSubmit={handleSubmit}>
                    <InputGroup size="lg" className="mb-3 signup-group">
                            <FormControl
                                aria-label="Recipient's email"
                                placeholder="Enter your email.."
                                className = "signup-input"
                                id={SIGNUP_TYPES.VIRE_WAITLIST} name={SIGNUP_TYPES.VIRE_WAITLIST}
                                onChange={handleEmailChange}
                                value={email}
                                type="email" required
                            />
                            <div className="signup-btn-div" id="signup-btn-div">
                                <Button variant="outline-secondary" id="sign-up-button" className="sign-up-button vire-category-button" type="submit">Sign Up</Button>
                            </div>
                        </InputGroup>
                    </form>
                </div>
            </div>
        </div>
    );
}
