import React, { useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from "react-helmet";
import { BlogCard } from '../components/BlogCard'
import edlyft from '../tmpImages/edlyft.png';
import ei from '../tmpImages/ei.png';
import dispatch from '../tmpImages/dispatch.png';
import pachama from '../tmpImages/pachama.png';
import modernhealth from '../tmpImages/modernhealth.png';
import corsali from '../tmpImages/corsali.png';
import top_10_mental_health from '../tmpImages/top_10_mental_health.png';
import mental_health_market_report from '../tmpImages/mental_health_trend_header.png';
import welbi from '../tmpImages/welbi.png';
import vv_1 from '../tmpImages/vv_1.png';
import teaching_to_tech_banner from '../tmpImages/teaching_to_tech.png'
import linkedIn_upload from '../tmpImages/linkedIn_upload.jpg';


export function Blog() {
    const [showMore, setShowMore] = useState(false);


    return <div>
        <Helmet>
            <title>Vire: Blog</title>
        </Helmet>
        <h3 className="blogpost-title">Blog</h3>
            <p className="blogpost-proposition">Read from our selection of founder stories and industry reviews.</p>
            <div className="blogpost-div">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={4} lg={4} className="blogcard-col">
                            <BlogCard
                                imageSrc={linkedIn_upload}
                                title="How to Upload your LI Profile to Vire"
                                link="/blog/linkedin-upload" />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className="blogcard-col">
                            <BlogCard
                                imageSrc={teaching_to_tech_banner}
                                title="From Teaching to Tech"
                                description="How your experience as an educator can transfer into the EdTech industry."
                                link="/blog/teaching-to-tech" />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className="blogcard-col">
                            <BlogCard
                                imageSrc={welbi}
                                title="Welbi"
                                description="Data-visualization and recommendations to tackle the social isolation epidemic."
                                link="/blog/welbi" />
                        </Col>
                    </Row>
                    <Row>
                            <Col xs={12} sm={12} md={4} lg={4} className="blogcard-col">
                                <BlogCard
                                    imageSrc={top_10_mental_health}
                                    title="Top 10 Startups Innovating in Mental Health"
                                    description="Our favorite startups in mental health based on their innovative approaches, rapid growth and traction."
                                    link="/blog/top-10-mental-health" />
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} className="blogcard-col">
                                <BlogCard
                                    imageSrc={mental_health_market_report}
                                    title="Startup Market Trends in Mental Health"
                                    description="Market trends report on the mental health industry."
                                    link="/blog/mental-health-trends" />
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} className="blogcard-col">
                                <BlogCard
                                    imageSrc={corsali}
                                    title="Corsali"
                                    description="Creating economic opportunity through labeling data anywhere on low-bandwidth mobile devices."
                                    link="/blog/corsali" />
                            </Col>
                        </Row>
                    {showMore && (
                    <>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} className="blogcard-col">
                                <BlogCard
                                    imageSrc={pachama}
                                    title="Pachama"
                                    description="Applying Artificial Intelligence to measure forest carbon sequestration."
                                    link="/blog/pachama" />
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4}  className="blogcard-col">
                                <BlogCard
                                    imageSrc={modernhealth}
                                    title="Modern Health"
                                    description="Democratizing access to mental health resources by distributing through employers."
                                    link="/blog/modernhealth" />
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4}  className="blogcard-col">
                                <BlogCard
                                    imageSrc={edlyft}
                                    title="Edlyft"
                                    description="Equipping more students with the tools to crush their STEM coursework and land top jobs in tech."
                                    link="/blog/edlyft"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} className="blogcard-col">
                                <BlogCard
                                    imageSrc={ei}
                                    title="Emerging Impact"
                                    description="Providing the technical expertise for non-profits to address the most pressing needs of society and our planet."
                                    link="/blog/emergingimpact"/>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} className="blogcard-col">
                                <BlogCard
                                    imageSrc={dispatch}
                                    title="Dispatch Goods"
                                    description="How Dispatch Goods is changing the way we consume as a society."
                                    link="/blog/dispatch-goods" />
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} className="blogcard-col">
                                <BlogCard
                                    imageSrc={vv_1}
                                    title="The Beginning of Virtuous Ventures"
                                    description="“If you stand for nothing Burr, what will you fall for?”"
                                    link="/blog/beginning-of-vv" />
                            </Col>
                        </Row>
                    </>
                    )}
                </Container>
                {!showMore && (
                    <button className="vire-sign-up-button vire-category-button vire-nav-button align-center btn btn-outline-secondary load-more-button" onClick={() => setShowMore(true)}>Load More</button>
                )}
            </div>
    </div>;
}