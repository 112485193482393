import React from "react";

export function Contact() {
    return <div>
        <h1>[Contact]</h1>
    </div>
}

export function Whoops404() {
    return (
        <div className="whoops-page">
            <h1><b className="job-h3-purple">404</b>: Page not found</h1>
            <p>Sorry! It looks like nothing is here. Please try another route.</p>
        </div>
    )
}

export function Warmup() {
    return <div>
        <h1>200</h1>
    </div>
}
