import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from "react-helmet";
import profile_pic_1 from '../tmpImages/profile/profile_pic_1.jpg';
import profile_pic_2 from '../tmpImages/profile/profile_pic_2.jpg';
import profile_pic_3 from '../tmpImages/profile/profile_pic_3.jpg';
import profile_pic_4 from '../tmpImages/profile/profile_pic_4.jpg';
import profile_pic_5 from '../tmpImages/profile/profile_pic_5.jpg';
import profile_pic_6 from '../tmpImages/profile/profile_pic_6.jpg';
import profile_pic_7 from '../tmpImages/profile/profile_pic_7.jpg';
import { Button, Form, Image } from "react-bootstrap";
import { AshbyLogo, GreenhouseLogo, LeverLogo, NoisyIcon, WorkableLogo } from "../components/Icons";
import optimization_venn_diagram from '../tmpImages/optimization_venn_diagram.png';
import employer_screenshot_1 from '../tmpImages/employer_screenshot_1.png';
import employer_screenshot_3 from '../tmpImages/employer_screenshot_3.png';
import employer_screenshot_4 from '../tmpImages/employer_screenshot_4.png';
import employer_screenshot_5 from '../tmpImages/employer_screenshot_5.png';
import employer_screenshot_6 from '../tmpImages/employer_screenshot_6.png';
import { sendEmployerEmail } from "../components/SendEmail";
import PropTypes from 'prop-types';

Employers.propTypes = {
    employerScrollToRef: PropTypes.object.isRequired
};


export function Employers({employerScrollToRef}) {

    const scrollDown = (ref) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        sendEmployerEmail(e.target.formBasicEmail.value, e.target.formBasicCompany.value, e.target.atsLink.value, e.target.formBasicMessage.value, 'employer-sign-up-button', 'employer-submit-success', 'employer-submit-error')
    };

    return (
        <div>
            <Helmet>
                <title>Vire: Employers</title>
            </Helmet>
            <Container fluid>
            <div className="purple-section">
                <Row className="vire-employer-section">
                    <Col xs={12} sm={12} md={6} lg={6} className="vire-employer-header">
                        <h1 className="job-h1">Find your perfect candidate match</h1>
                        <p>The original job-candidate matching platform that gathers the right data to assess true quality fit.</p>
                        <div>
                            <Button className="vire-sign-up-button vire-category-button"  onClick={() => scrollDown(employerScrollToRef)}>Start Now</Button>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className="candidate-img-container">
                            <Row>
                                <Image className="candidate-img" src={profile_pic_1}/>
                                <Image className="candidate-img" src={profile_pic_4}/>
                                <Image className="candidate-img" src={profile_pic_7}/>
                            </Row>
                            <Row className="candidate-img-middle-row">
                                <Image className="candidate-img" src={profile_pic_3}/>
                                <Image className="candidate-img" src={profile_pic_5}/>
                            </Row>
                            <Row className="candidate-img-last-row">
                                <Image className="candidate-img" src={profile_pic_2}/>
                                <Image className="candidate-img" src={profile_pic_6}/>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="vire-silence-noise-container">
                <NoisyIcon size="50"/>
                <h2>Silence the Noise</h2>
                <div className="employer-silence-noise-section">
                    <p>AI and Easy Apply methods have lowered barriers to job applications, but have generated more noise in your candidate search.</p>
                    <p>The technology is not insufficient, the underlying data is.</p>
                    <p>It's as simple as:</p>
                    <h2><span className="job-matching-word-emphasis">Better Data</span> + AI  = <b className="job-matching-word-emphasis">Better Hires</b></h2>
                </div>
            </div>
            <div className="employers-selling-points-container">
                <Row className="employers-selling-points-row">
                    <Col xs={12} sm={12} md={12} lg={6} className="employer-point-column">
                        <div className="employer-point-container">
                            <h1>🔎</h1>
                            <h3 className="employers-selling-point"><b>Find and review candidates based on direct criteria</b></h3>
                            <p>No more guessing or using indirect indicators to assess if they have demonstrated a specific skill at their previous roles or have worked with a specific user persona.</p>
                            <p>The data is <b>explicit</b>.</p>
                            <p>Because we guide candidates to provide this data directly.</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} className="employer-point-column">
                        <Image className="employer-screenshot" src={employer_screenshot_1}/>
                    </Col>
                </Row>
                <Row className="employers-selling-points-row">
                    <Col xs={12} sm={12} md={12} lg={{span:5, order:"last"}} className="employer-point-column">
                        <div className="employer-point-container">
                            <h1>📬</h1>
                            <h3 className="employers-selling-point"><b>Get the reply</b></h3>
                            <p>Clear, structured profiles enable you to quickly assess candidate fit AND understand what motivates them, so you know how to phrase your outreach.</p>
                            <p>Don't waste your time on candidates with priorties that conflict with your those of your business.</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={3} className="employer-point-column">
                        <Image className="employer-screenshot" src={employer_screenshot_3}/>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4} className="employer-point-column employer-point-column-center">
                        <Image className="employer-screenshot" src={employer_screenshot_4}/>
                    </Col>
                </Row>
                <Row className="employers-selling-points-row">
                    <Col xs={12} sm={12} md={12} lg={5} className="employer-point-column">
                        <div className="employer-point-container">
                            <h1>💎</h1>
                            <h3 className="employers-selling-point"><b>Reduce bias in JD/resume language</b></h3>
                            <p>Don’t let AI further propagate the bias in modern recruitment practices.</p>
                            <p>With shared and standardized language, you can find the overlooked gems.</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} className="employer-point-column employer-point-column-center">
                        <Image className="employer-screenshot" src={employer_screenshot_6}/>
                    </Col>
                </Row>
                <Row className="employers-selling-points-row">
                    <Col xs={12} sm={12} md={12} lg={6} className="employer-point-column">
                        <div className="employer-point-container">
                            <h1>🌟</h1>
                            <h3 className="employers-selling-point"><b>Stand out with your mission and culture</b></h3>
                            <p>Getting drowned out in job boards by larger organizations and pay-to-play promotional wars?</p>
                            <p>Matching criteria incorporating mission and cultural elements helps surface your opportunities to like-minded candidates.</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={{span:6, order:"first"}} className="employer-point-column employer-point-column-center">
                        {/* <Image className="employer-screenshot" src={employer_screenshot_3}/> */}
                        {/* <Image className="employer-screenshot" src={employer_screenshot_4}/> */}
                        <Image className="employer-screenshot" src={employer_screenshot_5}/>
                    </Col>
                </Row>
            </div>
            <div className="small-purple-section">
                <div className="hire-smarter-section">
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={7} className="hire-smarter-col">
                            <h2>Save <span className="job-matching-word-emphasis">time</span> and <span className="job-matching-word-emphasis">money</span> by hiring smarter.</h2>
                            <p>Find quality fit upfront at the intersection of candidate expertise, motivations, and optimal work environment.</p>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={5}>
                            <Image className="optimization-venn-diagram" src={optimization_venn_diagram}/>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="ats-logos-section">
                <h2>Integrations with your ATS</h2>
                <Row className="ats-logos-container">
                    <Col>
                        <AshbyLogo size="120"/>
                    </Col>
                    <Col>
                        <LeverLogo size="120"/>
                    </Col>
                    <Col>
                        <GreenhouseLogo size="120"/>
                    </Col>
                    <Col>
                        <WorkableLogo size="120"/>
                    </Col>
                </Row>
                <p>And more...</p>
            </div>
            <div className="employer-get-started-div" ref={employerScrollToRef}>
                <h2 className="employer-get-started-header">Ready to get started?</h2>
                <p>Contact us to learn more and tell us what you are looking for.</p>
                <div className="employer-get-started-form-container">
                    <Form className="employer-get-started-form" onSubmit={handleSubmit}>
                        <Form.Group className="employer-get-started-form-group" controlId="formBasicEmail">
                            <Form.Label>Company email address*</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" required/>
                        </Form.Group>
                        <Form.Group className="employer-get-started-form-group" controlId="formBasicCompany">
                            <Form.Label>Company name*</Form.Label>
                            <Form.Control type="text" placeholder="Enter company" required/>
                        </Form.Group>
                        <Form.Group className="employer-get-started-form-group" controlId="atsLink">
                            <Form.Label>ATS job board link</Form.Label>
                            <Form.Control type="url" placeholder="Enter ats job link" />
                        </Form.Group>
                        <Form.Group className="employer-get-started-form-group" controlId="formBasicMessage">
                            <Form.Label>Message*</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter message" required/>
                        </Form.Group>
                        <p className="signup-error-message" id="employer-submit-error">There was an error. Try again or send us a direct email.</p>
                        <p className="signup-complete-message" id="employer-submit-success">Thanks! We'll be in touch soon.</p>
                        <Button id="employer-sign-up-button" className="vire-sign-up-button vire-category-button" type="submit">Submit</Button>
                    </Form>
                </div>
                <p className="employer-reach-out">Or reach out at <a className="purple-link" href="mailto:hello@joinvire.com">hello@joinvire.com</a>!</p>
            </div>
            </Container>
    </div>
    );
}